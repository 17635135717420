import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"

const BooksPage = () => {
  return (
    <Layout>
      <Head title="Books" />
      <h1>Books</h1>
      <p>Coming Soon</p>
    </Layout>
  )
}

export default BooksPage
